.inputText {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 20px;
}

.dropdown {
  width: 100%;
  margin-bottom: 20px;
}
