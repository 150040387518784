.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  overflow-y: auto;
  gap: 24px;
  min-height: 0;
}

.inputText {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 20px;
}

.submitButton {
  width: 100%;
}

.card {
  width: 450px;
}
